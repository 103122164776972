import React, { useMemo } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import { Typography } from '@material-ui/core';

import { roleStrings } from 'shared/modules/permissions/user/actions';
import Box from 'shared/styleguide/atoms/Box';
import Linker from 'shared/styleguide/atoms/Links/Linker';
import { getAuthType, switchToAccount } from 'shared/utils/AuthTypes';
import Avatar from 'shared/styleguide/molecules/Avatar';

interface CollaboratorItemProps {
  accessToCompany: string;
  accessToName: string;
  accessToEmail: string;
  allowPasswordLogin: boolean;
  domains: string[];
  hoverColor: string;
  mfaRequired: boolean;
  requestClosePopover: (...args) => (...arg) => void;
  role: number;
  targetId: number;
  user: {
    mfaEnabled: boolean;
    name: string;
  };
}

export const CollaboratorItem: React.FC<CollaboratorItemProps> = ({
  role,
  accessToCompany,
  accessToName,
  accessToEmail,
  allowPasswordLogin,
  requestClosePopover,
  targetId,
  user,
  mfaRequired,
  domains,
  hoverColor = '',
}) => {
  const authType = useMemo(() => getAuthType(targetId), [targetId]);

  const switchToAccountHandler = (e) => {
    e.preventDefault();
    if (ssoRequiredButNotLoggedIn) {
      /* eslint-disable no-alert */
      requestClosePopover(null);
      alert('You must login via SSO to switch to this account.');
      return;
    }
    if (authType?.mode === 'password' && !user.mfaEnabled && mfaRequired) {
      /* eslint-disable no-alert */
      alert('You must enable 2-Factor authentication to switch to this account.');
      requestClosePopover(null);
      return;
    }
    if (authType?.mode === 'sso' && !allowPasswordLogin) {
      switchToAccount(targetId);

      if (!authType.token) {
        window.location.href = authType.accountUrl.url;
        return;
      }
    }
    if (requestClosePopover) {
      requestClosePopover(`/account/${targetId}`)(e);
    }
  };

  const ssoRequiredButNotLoggedIn = !allowPasswordLogin && authType?.mode === 'sso' && !authType?.token;
  const loggedInUsingPassword = ssoRequiredButNotLoggedIn || authType?.mode !== 'sso';

  return (
    <Box
      disabled={ssoRequiredButNotLoggedIn}
      component={Linker}
      direction="row"
      align="center"
      gap="xsmall"
      padding="medium"
      hoverColor={hoverColor}
      onClick={switchToAccountHandler}
      css={(theme) => ({
        'cursor': 'pointer',
        'borderBottom': `1px solid ${theme.color400}`,
        '& img': {
          width: 40,
          borderRadius: '50%',
          transition: theme.transitions.create(['borderColor', 'box-shadow'], {
            easing: theme.transitions.easing.sharp,
          }),
        },
        '&:hover img': theme.overrides.MuiAvatar.root['&:hover'],
        '&:hover .MuiAvatar-root': theme.overrides.MuiAvatar.root['&:hover'],
      })}
    >
      <Box
        direction="row"
        align="center"
      >
        <Avatar
          fullName={accessToCompany || accessToName}
          email={accessToEmail}
          gravatarSize={75}
        />
        {
          mfaRequired && (
            <div>
              <LockIcon color="error" />
            </div>
          )
        }
      </Box>
      <Box>
        <Typography variant="subtitle1" color="textPrimary">{accessToCompany || accessToName}</Typography>
        <Typography variant="subtitle2" color="textSecondary">{roleStrings[role]}</Typography>
        {
          ((role === 2 || role === 1) && domains && domains.length) && (
            <Typography variant="subtitle2" color="textSecondary">{domains.join(', ')}</Typography>
          )
        }
        {
          (mfaRequired && loggedInUsingPassword) && (
            <Typography
              color="error"
              variant="subtitle2"
            >
              MFA is required to access this account
            </Typography>
          )
        }
        {
          (ssoRequiredButNotLoggedIn) && (
            <Typography
              color="error"
              variant="subtitle2"
            >
              Single Sign On is required to access this account
            </Typography>
          )
        }
      </Box>
    </Box>
  );
};

export default CollaboratorItem;
